import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

const articleTemplate = (  { data: { strapi: { article } }, location } ) => {

	return (
		<>
			<SEO title={ article.title } description={ article.seoDescription } pathname={ location.pathname } />

			<div className="article wrapper">
				<div className="article__wrapper">

					<h1 className="article__header">{ article.title }</h1>
					{ article.subTitle &&
						<h2 className="article__sub-header">{ article.subTitle }</h2>
					}

					<div className="article__body-content">
						{ article && article.content &&
							<DynamicZone components={ article.content } />
						}
					</div>

				</div>
			</div>
		</>
	)
}

export default articleTemplate

export const query = graphql`
	query articleQuery($id: ID!) {
		strapi {
			article(id: $id) {
				id
				title
				subTitle
				seoDescription
				content {
					... on StrapiQuery_ComponentArticleTextBlock {
						id
						text
					}
					... on StrapiQuery_ComponentArticleImage {
						id
						displayBorder
						rotateImage
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentArticleVideo {
						id
						videoNumber
						videoTitle
					}
					... on StrapiQuery_ComponentArticleQuote {
						id
						author
						quote
					}
				}
			}
		}
	}
`